.blogs-section {
  padding-top: ac(100px, 40px);
  padding-bottom: ac(100px, 60px);
  overflow: hidden;

  &__wrap {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
  }

  &__grid {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-column-gap: ac(20px, 14px);
    grid-row-gap: ac(50px, 24px);
  }

  &__load-btn {
    margin-top: ac(70px, 38px);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 42px;
    border-radius: 22px;
    padding-left: 19px;
    padding-right: 19px;
    padding-top: 11px;
    padding-bottom: 11px;
    background: var(--cl-white);
    font-size: 15px;
    font-weight: 600;
    font-family: var(--font-main);
    line-height: normal;
    color: var(--cl-dark-grey);
    border: 1px solid var(--cl-dark-grey);
    transition: all 0.3s ease;

    &:hover {
      background-color: var(--cl-medium-grey);
      color: var(--cl-light-grey);
      border-color: var(--cl-red);
    }

    &:active {
      transform: scale(0.9);
    }
  }

  @mixin media 840 {
    &__grid {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }

  @mixin max-sm {
    &__grid {
      grid-template-columns: repeat(1, minmax(0, 1fr));
      max-width: 390px;
    }
  }

  @mixin media 425 {
    &__grid {
      max-width: 100%;
    }
  }
}
