.sectors-section {
  padding-top: ac(200px, 80px);
  padding-bottom: ac(100px, 40px);
  overflow: hidden;
  z-index: 10;

  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  &__select {
    width: 100%;
    margin-bottom: -87px;
    position: relative;
    z-index: 10;

    .meta-select {
      width: 304px;

      @mixin media 425 {
        width: 100%;
      }
    }
  }

  &__container {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  &__item {
    width: 100%;
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  &__left-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 49.22%;
    padding-top: 127px;
    padding-bottom: 54px;
  }

  &__right-content {
    padding-top: ac(61px, 50px);
    width: 49.22%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    z-index: 2;
    min-height: ac(593px, 320px);
  }

  &__title {
    &:not(:last-child) {
      margin-bottom: ac(28px, 24px);
    }
  }

  &__text {
    width: 82.48%;
    padding-bottom: 24px;
  }

  &__image {
    @mixin aspect-ratio 567, 593;
    position: absolute;
    z-index: -1;
    width: 90.29%;
    top: 0;
    right: 0;

    @mixin media 490 {
      width: 100%;
    }
  }

  &__benefits {
    position: relative;
    z-index: 2;
    column-count: 2;
    column-gap: ac(20px, 10px);
    width: 100%;
  }

  &__benefit {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    background: var(--cl-white);
    box-shadow: 0px 4px 20px 5px rgba(0, 0, 0, 0.05);
    font-size: ac(16px, 14px);
    line-height: normal;
    font-weight: 600;
    color: var(--cl-red);
    padding: ac(20px, 16px);
    margin-bottom: 10px;

    span {
      @mixin max-line-length 4;
    }
  }

  &__slider {
    width: 100%;
    margin-top: -54px;

    &:not(:last-child) {
      margin-bottom: ac(50px, 36px);
    }
  }

  &__slider-title {
    font-size: ac(30px, 20px);
    color: var(--cl-red);
    font-weight: 500;
    line-height: normal;
    margin-bottom: ac(30px, 24px);
    max-width: 49.22%;
  }

  @mixin media 901 {
    &__benefits {
      column-count: 1;
    }
  }

  @mixin max-md {
    &__select {
      margin-bottom: 24px;
    }

    &__left-content {
      padding-top: 0;
    }

    &__container {
      padding-bottom: 10px;
    }
  }

  @mixin media 690 {
    &__slider {
      margin-top: 20px;
    }

    &__slider-title {
      max-width: calc(100% - 110px);
    }
  }

  @mixin max-sm {
    &__item {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    }

    &__left-content {
      width: 100%;
      padding-bottom: 24px;
    }

    &__right-content {
      width: 100%;
      min-height: 85vw;
    }

    &__text {
      padding-bottom: 5px;
    }

    &__benefits {
      column-count: 2;
    }
  }

  @mixin media 425 {
    &__right-content {
      padding-top: 160px;
    }

    &__benefits {
      column-count: 1;
    }

    &__benefit {
      box-shadow: 0px 4px 10px 4px rgba(0, 0, 0, 0.12);
    }
  }
}
