.stats-section {
  padding-top: ac(92px, 40px);
  padding-bottom: ac(100px, 40px);

  @mixin media 1026 {
    padding-top: 40px;
  }

  &__wrap {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: row-reverse;
  }

  &__text-image-slider {
    width: 100%;
    max-width: 100%;
    overflow: visible;

    .work-for-us-section__benefits {
      position: absolute;
      top: -20px;
      right: ac(-50px, -20px);
      z-index: 5;
      width: auto;
      flex-direction: column !important;
      align-items: stretch !important;
      grid-template-columns: repeat(1,minmax(0,1fr));
      margin-top: 0;

      .work-for-us-section__benefit {
        font-size: ac(24px, 20px);
        cursor: pointer;
        transition: .3s ;
        padding-left: ac(24px, 16px);
        padding-right: ac(24px, 16px);

        @media (max-width: 451px) {
          border: 1px solid var(--cl-medium-grey);
        }

        &:hover {
          background-color: var(--cl-medium-grey);
          color: var(--cl-white);
        }

        &.active {
          background-color: var(--cl-red);
          color: var(--cl-white);
          border-color: var(--cl-red);
        }
      }

      @media (max-width: 451px) {
        right: -10px;
        top: calc(89.33vw - 80px);
      }
    }
  }

  &__text-image-slide {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row-reverse;

    .stats-section__image {
      @mixin aspect-ratio 556, 590;

      @media (max-width: 451px) {
        margin-bottom: 30px;
      }
    }
  }

  &__content {
    margin-top: 16px;
    width: 49.22%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    @mixin max-xl {
      width: 53%;
    }

    @mixin media 1026 {
      padding-top: 0;
    }
  }

  &__logo {
    @mixin aspect-ratio 655.99, 161.12;
    width: ac(290px, 190px);

    img {
      object-fit: contain;
    }

    &:not(:last-child) {
      margin-bottom: ac(32px, 24px);
    }
  }

  &__title {
    margin-left: ac(-2px, 0px);

    &:not(:last-child) {
      margin-bottom: ac(28px, 14px);
    }
  }

  &__text {
    &:not(:last-child) {
      margin-bottom: ac(30px, 28px);
    }
  }

  .line-decor {
    margin-bottom: ac(15px, 12px);
  }

  &__list {
    &:not(:last-child) {
      margin-bottom: ac(30px, 28px);
    }
  }

  &__buttons {
    margin-left: ac(2px, 0px);
  }

  &__image {
    width: 43.57%;
    @mixin aspect-ratio 556, 696;
  }

  &.hiring-page {
    padding-top: ac(100px, 40px);

    .stats-section__image {
      @mixin aspect-ratio 556, 593;
    }

    .stats-section__text {
      p,
      li {
        &:not(:last-child) {
          margin-bottom: 6px;
        }
      }
    }
  }
}

.stats {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-column-gap: ac(20px, 18px);
  grid-row-gap: ac(20px, 12px);

  &__item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 5px;
  }

  &__count {
    font-family: var(--font-main);
    font-size: ac(50px, 40px);
    line-height: 120%;
    color: var(--cl-grey);
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  &__text {
    h4, .title-h4 {
      color: var(--cl-grey);
      line-height: normal;
      font-size: ac(20px, 14px);
      font-weight: 500;
    }
  }
}

@mixin max-lg {
  .stats-section {
    &__wrap {
      align-items: center;
    }
  }

  .stats {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@mixin media 860 {
  .stats-section {
    &__content {
      width: 60%;
    }

    &__image {
      width: 37%;
    }
  }
}

@media (max-width: 640px) {
  .stats-section {
    &__wrap {
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      grid-row-gap: 28px;
    }

    &__text-image-slide {
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      grid-row-gap: 28px;
    }

    &__content {
      margin-top: 0;
      width: 100%;
    }

    &__image {
      width: 100%;
      max-width: 404px;
      @mixin aspect-ratio 335, 429;
    }

    .line-decor {
      margin-left: 3.5%;
    }
  }

  .stats {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    grid-row-gap: 0;

    margin: 0 auto;
    max-width: 93%;

    &__item {
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      gap: 20px;

      &:not(:first-child) {
        padding-top: 12px;
      }

      &:not(:last-child) {
        padding-bottom: 12px;
        border-bottom: 1px solid var(--cl-medium-grey);
      }
    }

    &__count {
      min-width: 88px;
    }

    &__text {
      h4, .title-h4 {
        font-size: 18px;
        line-height: 28px;
      }
    }
  }
}

@mixin max-xs {
  .stats-section {
    .line-decor {
      margin-left: 0;
    }
  }

  .stats {
    max-width: 100%;
  }
}
