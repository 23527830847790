.meta-input,
.meta-upload-file {
  label {
    font-family: var(--font-main);
    font-size: ac(18px, 16px);
    line-height: normal;
    font-weight: 600;
    color: var(--cl-white);
    padding-bottom: ac(15px, 12px);
    cursor: default;
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;

    span {
      margin-left: 4px;
      color: var(--cl-red);
    }
  }

  &.dark-type {
    label {
      color: var(--cl-grey);

      span {
        color: var(--cl-red);
      }
    }
  }
}

.meta-input {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;

  input,
  textarea {
    width: 100%;
    font-size: ac(18px, 16px);
    line-height: normal;
    font-weight: 500;
    font-family: var(--font-main);
    color: var(--cl-grey);
    border: 2px solid transparent;
    border-radius: 25px;
    outline: none;
    transition: box-shadow 0.25s ease, border-color 0.3s ease,
      caret-color 0.3s ease, color 0.3s ease, opacity 0.3s ease;
    padding: 5px 30px;
    height: 50px;
    background: var(--cl-white);
    @mixin transparent-bg-input var(--cl-grey);

    &::placeholder {
      transition: all 0.3s ease;
      color: var(--cl-grey);
      opacity: 0.5;
    }

    &:focus {
      border-color: rgba(var(--cl-dark-blue-rgb) / 0.4);
      caret-color: var(--cl-red);
      box-shadow: 2px 2px 5px 0px rgba(var(--cl-dark-blue-rgb) / 0.3);
    }

    &.error {
      border-color: var(--cl-red);
      box-shadow: 2px 2px 5px 0px rgba(var(--cl-red-rgb) / 0.3);
    }
  }

  textarea {
    padding-top: 12px;
    min-height: ac(80px, 60px);
    border-bottom-right-radius: 4px;
    resize: vertical;
    clip-path: inset(0 0 -10px -10px round 25px 25px 4px 25px);

    &:focus {
      box-shadow: none;
    }
  }

  &__help,
  &__error {
    margin-top: 5px;
    font-family: var(--font-main);
    font-size: ac(14px, 12px);
    line-height: 1.2;
    font-weight: 600;
  }

  &__help {
    color: var(--cl-dark-grey);
  }

  &__error {
    color: var(--cl-red);
  }

  &__search-btn {
    position: absolute;
    right: ac(28px, 20px);
    top: 54%;
    transform: translateY(-50%);
    z-index: 10;
    cursor: pointer;
    font-size: 24px;
    color: var(--cl-dark-grey);
    transition: all 0.3s ease;

    i {
      color: var(--cl-dark-grey);
      transition: all 0.3s ease;
    }

    &:hover {
      transform: translateY(-50%) scale(1.05);

      i {
        color: var(--cl-red);
      }
    }
  }

  &.search-input {
    input {
      height: ac(80px, 64px);
      border-radius: ac(40px, 32px);
      padding-right: 64px;
      font-size: ac(30px, 20px);
      line-height: normal;
      font-weight: 400;
      caret-color: var(--cl-dark-grey);
    }
  }

  &.dark-type {

    input,
    textarea {
      border-color: var(--cl-medium-grey);

      &:focus {
        border-color: rgba(var(--cl-dark-blue-rgb) / 0.4);
      }
    }
  }
}

/*.is-safari {
  .first-form-container .default-select .default-select__list {
    &::-webkit-scrollbar {
      width: initial;
    }

    &::-webkit-scrollbar-track {
      background: initial;
      border-radius: initial;
    }

    &::-webkit-scrollbar-thumb {
      background: initial;
      border-radius: initial;
    }
  }
}*/

#site:not(.is-safari) {
  .meta-input textarea {
    &::-webkit-scrollbar {
      width: 3px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
      border-radius: 0;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--cl-red);
      border-radius: 6px;
    }
  }
}

.meta-upload-file {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  &__input {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: ac(18px, 16px);
    line-height: normal;
    font-weight: 500;
    font-family: var(--font-main);
    color: var(--cl-grey);
    border: 2px dashed var(--cl-grey);
    border-radius: 25px;
    transition: all 0.3s ease;
    padding: 8px ac(16px, 12px);
    cursor: pointer;
    position: relative;
    min-height: 50px;

    input {
      position: absolute;
      inset: 0;
      opacity: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;
    }

    input[type='file']::-webkit-file-upload-button {
      cursor: pointer;
    }

    span {
      cursor: pointer;
      @mixin max-line-length-one;
    }

    &:hover {
      border-color: rgba(var(--cl-dark-blue-rgb) / 0.8);
      box-shadow: inset 0px 0px 5px 0px rgba(var(--cl-dark-blue-rgb) / 0.3);
    }
  }

  &__info {
    padding-top: 6px;
    font-size: ac(14px, 12px);
    line-height: 1.2;
    font-weight: 500;
    font-family: var(--pp-font-main);
    text-align: center;
    color: var(--cl-grey);
    margin-left: auto;
    margin-right: auto;
  }

  &.drag {
    .meta-upload-file__input {
      background-color: var(--cl-light-grey);
      box-shadow: inset 0px 0px 10px 0px rgba(var(--cl-dark-blue-rgb) / 0.4);
    }
  }

  &.drop {
    .meta-upload-file__input {
      border-style: solid;
    }

    &:not(.done) {
      .meta-upload-file__input {
        transform: scale(0.9);
      }
    }
  }
}

.meta-checkbox-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  &__item {
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
}

.meta-checkbox {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;

  &__box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: ac(18px, 16px);
    min-width: ac(18px, 16px);
    height: ac(18px, 16px);
    border: 2px solid var(--cl-dark-grey);
    margin-right: ac(8px, 6px);
    transition: border-color 0.3s ease, background-color 0.3s ease;
    background: transparent;

    &:hover {
      border-color: var(--cl-red);
    }

    .check-clip,
    .circle-clip {
      width: 85%;
      height: 85%;
      background-color: var(--cl-red);
      opacity: 0;
      transition: opacity 0.3s ease;
    }

    .check-clip {
      clip-path: polygon(10% 52%, 40% 79%, 90% 18%, 100% 27%, 42% 100%, 0 62%);
    }

    .circle-clip {
      clip-path: circle(50%);
    }

    i {
      opacity: 0;
      transition: opacity 0.3s ease;
    }
  }

  input {
    display: none;
  }

  input[type='checkbox'] {
    + .meta-checkbox__box {
      border-radius: 4px;
    }
  }

  input[type='radio'] {
    + .meta-checkbox__box {
      border-radius: 50%;
    }
  }

  &__text {
    font-weight: 400;
    font-size: ac(14px, 12px);
    line-height: 1.2;
    font-family: var(--font-main);
    color: var(--cl-grey);

    a {
      font: inherit;
      font-weight: 600;
      color: var(--cl-red);
      text-decoration: underline;
      text-decoration-color: transparent;
      transition: all 0.25s ease;

      &:hover {
        text-decoration-color: var(--cl-red);
      }
    }
  }

  input:checked ~ .meta-checkbox__box {
    .check-clip,
    .circle-clip,
    i {
      opacity: 1;
    }

    &:hover {
      border-color: var(--cl-medium-grey);
    }
  }
}

.btn-checkbox {
  cursor: pointer;
  max-width: 100%;

  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: ac(19px, 16px);
    padding-right: ac(19px, 16px);
    padding-top: 11px;
    padding-bottom: 11px;
    border-radius: 25px;
    font-family: var(--font-main);
    font-size: ac(15px, 14px);
    font-weight: 600;
    line-height: normal;
    color: var(--cl-grey);
    background-color: var(--cl-white);
    border: 1px solid var(--cl-grey);
    transition: all 0.3s ease;

    span {
      max-width: 100%;
      @mixin max-line-length-one;
    }

    &:hover {
      background-color: var(--cl-light-grey);
      color: var(--cl-dark-grey);
    }
  }

  input {
    display: none;
  }

  input:checked ~ .btn-checkbox__content {
    background-color: var(--cl-grey);
    color: var(--cl-white);

    &:hover {
      border-color: var(--cl-light-grey);
    }
  }
}

.text-checkbox {
  cursor: pointer;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 4px;

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 4px;
    min-width: ac(14px, 12px);
    width: ac(14px, 12px);
    height: ac(14px, 12px);
    position: relative;
    transition: all .3s ease;
    opacity: 0;

    i {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
      font-size: ac(14px, 12px);
      color: var(--cl-grey);
      transition: all .3s ease;
    }

    i.icon-minus {
      opacity: 0;
    }

    i.icon-minus-second {
      opacity: 0;
    }

    &:hover {
      i {
        color: var(--cl-red);
      }
    }

    &.is-hidden {
      opacity: 0 !important;
      pointer-events: none;
    }

    &.active {
      i.icon-plus {
        opacity: 0;
      }

      i.icon-plus-second {
        opacity: 0;
      }

      i.icon-minus {
        opacity: 1;
      }

      i.icon-minus-second {
        opacity: 1;
      }
    }
  }

  &__content {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-family: var(--font-main);
    font-size: ac(17px, 15px);
    font-weight: 400;
    line-height: normal;
    color: var(--cl-grey);
    transition: all 0.3s ease;
    position: relative;

    /*&:before {
      content: '+';
      font: inherit;
      color: var(--cl-red);
      font-weight: 500;
      transition: all 0.3s ease;
      margin-right: 6px;
      min-width: 8.7px;
    }*/

    span {
      max-width: 100%;
      text-decoration: underline;
      text-decoration-color: transparent;
      /*@mixin max-line-length-one;*/
      transition: all 0.3s ease;

      &:hover {
        text-decoration-color: var(--cl-grey);
      }
    }

    &:hover {
      color: var(--cl-dark-grey);
    }
  }

  input {
    display: none;
  }

  input:checked ~ .text-checkbox__content {
    color: var(--cl-red);

    span {
      &:hover {
        text-decoration-color: var(--cl-red);
      }
    }
  }

  &.parent-checkbox {
    padding-bottom: 9px;

    .text-checkbox__icon {
      margin-right: ac(16px, 12px);
      width: ac(24px, 18px);
      height: ac(24px, 18px);

      i {
        font-size: ac(24px, 18px);
      }
    }

    .text-checkbox__content {
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: ac(200px, 180px);
      padding-left: ac(19px, 16px);
      padding-right: ac(19px, 16px);
      padding-top: 11px;
      padding-bottom: 11px;
      border-radius: 25px;
      font-family: var(--font-main);
      font-size: ac(15px, 14px);
      font-weight: 600;
      line-height: normal;
      color: var(--cl-grey);
      background-color: var(--cl-white);
      border: 1px solid var(--cl-grey);
      transition: all 0.3s ease;

      span {
        text-decoration: none !important;

        &.red-color {
          color: var(--cl-red);
        }
      }

      &:hover {
        background-color: var(--cl-light-grey);
        color: var(--cl-dark-grey);
      }

    }

    input:checked ~ .text-checkbox__content {
      background-color: var(--cl-grey);
      color: var(--cl-white);

      span {
        &.red-color {
          color: var(--cl-white);
        }
      }

      &:hover {
        border-color: var(--cl-light-grey);
      }
    }
  }
}

.filters-main-parent {
  opacity: 0;
  transition: all .4s ease;

  ul {
    transition: all .3s ease;
    padding-left: ac(32px, 20px);

    &.is-open {
      &:first-of-type {
        padding-top: 6px;
      }

      &:last-of-type {
        padding-bottom: 10px;
      }
    }

    ul {
      /*padding-top: 6px;
      padding-bottom: 16px;*/
      padding-left: 10px;
      ul {
        padding-left: 10px;

        &.is-open {
          &:first-of-type {
            padding-top: 0;
          }

          &:last-of-type {
            padding-bottom: 0;
          }
        }
      }

      &.is-open {
        &:first-of-type {
          padding-top: 0;
        }

        &:last-of-type {
          padding-bottom: 0;
        }
      }
    }
  }

  li {

  }
}

.meta-select {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  label {
    font-family: var(--font-main);
    font-size: ac(18px, 16px);
    line-height: normal;
    font-weight: 600;
    color: var(--cl-dark-grey);
    padding-bottom: ac(15px, 12px);
    cursor: pointer;
  }

  select {
    opacity: 0;
    height: 50px;
  }

  .choices {
    width: 100%;
    margin-bottom: 0;
    overflow: visible;

    &:not(.is-open) {
      overflow: hidden;
    }

    &__inner {
      border: 1px solid var(--cl-light-grey);
      border-radius: 25px !important;
      outline: none;
      transition: box-shadow 0.3s ease, border-color 0.3s ease,
        border-radius 0.3s ease, caret-color 0.3s ease, color 0.3s ease;
      padding: 10px 60px 10px ac(30px, 24px);
      background: transparent;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      @mixin max-line-length-one;
      width: 100%;
      cursor: pointer;
      font-size: ac(18px, 16px);
      line-height: normal;
      font-weight: 400;
      font-family: var(--font-main);
      color: var(--cl-dark-grey);
      height: 50px;

      &:hover {
        border-color: var(--cl-dark-blue);
      }
      &.error {
        border-color: var(--cl-red);
        box-shadow: 2px 2px 5px 0px rgba(var(--cl-red-rgb) / 0.3);
      }
    }
    &.is-open {
      &:not(.is-flipped) {
        .choices__list--dropdown,
        .choices__list[aria-expanded] {
          margin-top: 8px;
        }
      }

      &.is-flipped {
        .choices__list--dropdown,
        .choices__list[aria-expanded] {
          margin-bottom: 8px;
        }
      }
    }

    &__item {
      @mixin max-line-length-one;
    }

    &__placeholder {
      color: var(--cl-dark-grey);
      opacity: 1;
    }

    &__list {
      padding: 0;
      background-color: var(--cl-white);

      .choices__item {
        padding-right: ac(16px, 12px);
        font-size: ac(18px, 16px);
        line-height: normal;
        font-weight: 400;
        font-family: var(--font-main);
        color: var(--cl-dark-grey);
        transition: all 0.3s ease;
        text-decoration: underline;
        text-decoration-color: transparent;

        &.is-selected {
          color: var(--cl-red);
        }
      }

      &--multiple {
        color: var(--cl-dark-blue);
        .choices__item--selectable {
          display: none;
        }

        + .choices__input {
          display: none;
        }
      }

      &--dropdown {
        .choices__item {
          color: var(--cl-grey);
        }
      }

      &:not(.choices__list--single) {
        .choices__placeholder {
          display: none;
        }

        .choices__item {
          &:hover {
            color: var(--cl-medium-grey) !important;
          }

          &.is-selected {
            color: var(--cl-red) !important;

            &:hover {
              color: var(--cl-red) !important;
            }
          }
        }
      }
    }

    &[data-type*='select-one'],
    &[data-type*='select-multiple'] {
      cursor: pointer;
      &:after {
        top: 50%;
        right: ac(28px, 16px);
        transform: translateY(-50%) scale(1, 1);
        content: '\e905';
        font-family: 'icomoon' !important;
        speak: never;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        transition: transform 0.3s ease;
        font-size: 16px;
        border: none;
        width: auto;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;

        /* Better Font Rendering =========== */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: var(--cl-dark-grey);
      }
    }

    &.is-open {
      &:after {
        transform: translateY(-50%) scale(1, -1);
      }
    }

    &__list--dropdown .choices__item--selectable.is-highlighted,
    &__list[aria-expanded] .choices__item--selectable.is-highlighted {
      background-color: transparent;

      &:not(.is-selected) {
        color: var(--cl-grey);
        text-decoration-color: transparent;
      }
    }

    &__list--dropdown,
    .choices__list[aria-expanded] {
      border: 2px solid var(--cl-grey);
      z-index: 20;
      border-radius: 6px;
    }

    &[data-type*='select-one'] .choices__input {
      margin: 2px;
      max-width: calc(100% - 4px);
      border: 2px solid var(--cl-grey);
      border-radius: 8px;
      background: var(--transparent);
      color: var(--cl-dark-grey);
      font-size: ac(16px, 14px);
      line-height: 1.2;
      font-weight: 500;
      font-family: var(--font-main);
    }

    &[data-type*='select-multiple'] .choices__inner {
      cursor: pointer;
    }
  }

  &__list {
    display: none;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    max-width: 100%;
    grid-column-gap: 10px;
    grid-row-gap: 8px;

    &.is-visible {
      margin-top: 10px;
      display: flex;
    }
  }

  &__list-item {
    position: relative;
    font-size: ac(16px, 14px);
    line-height: 1.2;
    font-weight: 500;
    font-family: var(--font-main);
    padding-right: 14px;
    cursor: default;
    color: var(--cl-dark-blue);

    &:after {
      content: '';
      cursor: pointer;
      display: block;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      width: 10px;
      height: 10px;
      transition: background-color 0.25s ease, opacity 0.25s ease,
        transform 0.25s ease;
      opacity: 0.5;
      background: var(--cl-dark-blue);
      clip-path: polygon(
        20% 0%,
        0% 20%,
        30% 50%,
        0% 80%,
        20% 100%,
        50% 70%,
        80% 100%,
        100% 80%,
        70% 50%,
        100% 20%,
        80% 0%,
        50% 30%
      );
    }

    &:hover {
      &:after {
        opacity: 1;
        transform: translateY(-50%) scale(1.15);
      }
    }
  }
  &__help,
  &__error {
    margin-top: 5px;
    font-family: var(--font-main);
    font-size: ac(14px, 12px);
    line-height: 1.2;
    font-weight: 600;
  }

  &__help {
    color: var(--cl-dark-grey);
  }

  &__error {
    color: var(--cl-red);
  }
}
