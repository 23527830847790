.jobs-slider {
  overflow: visible;
  width: 100%;
  max-width: 100%;

  .job-card {
    height: auto;
    padding-top: 28px;
  }

  @mixin media 690 {
    .slider-buttons {
      position: absolute;
      top: 0;
      right: 0;
      transform: translateY(-100%);
      padding-bottom: 18px;
    }
  }
}
