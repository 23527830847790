.first-search-section {
  margin-top: ac(154px, 82px);
  padding-top: ac(102px, 64px);
  padding-bottom: ac(70px, 44px);
  overflow: hidden;
  margin-bottom: ac(100px, 40px);
  background: var(--cl-medium-grey);
  z-index: 10;

  &__wrap {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &__title {
    color: var(--cl-light-grey);

    &:not(:last-child) {
      margin-bottom: ac(30px, 24px);
    }
  }

  &__search {
    width: 100%;
    max-width: ac(844px, 628px);

    .meta-input {
      width: 100%;
    }
  }

  &.jobs-page {
    background: var(--cl-light-grey);
    margin-bottom: 0;

    .first-search-section__title {
      color: var(--cl-dark-grey);
    }
  }
}
