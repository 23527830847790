.blog-card {
  position: relative;
  z-index: 1;
  cursor: pointer;

  &__image {
    @mixin aspect-ratio 629, 385;
    width: 100%;
    clip-path: inset(0 0 0 0);

    img {
      transition: transform 0.3s ease;
      object-position: top;
    }
  }

  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-top: ac(29px, 24px);
  }

  &__date {
    font-size: ac(16px, 14px);
    font-weight: 600;
    line-height: normal;
    margin-bottom: ac(21px, 16px);
    font-family: var(--font-main);
    color: var(--cl-grey);
  }

  &__name {
    font-size: ac(25px, 18px);
    font-weight: 600;
    line-height: normal;
    width: 100%;
    padding-bottom: ac(21px, 18px);
    position: relative;
    margin-bottom: 20px;
    padding-right: ac(40px, 10px);

    &:before {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 47.5%;
      background: var(--cl-medium-grey);
      height: 3px;
      transition: width 0.4s ease;
    }
  }

  &__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: var(--cl-dark-grey);
    transition: margin-left 0.4s ease, transform 0.4s ease;
    margin-left: 0;
  }

  &:hover {
    img {
      transform: scale(1.03);
    }

    .blog-card__name {
      &:before {
        width: 100%;
      }
    }

    .blog-card__btn {
      margin-left: calc(50% - 7px);
      transform: scale(1.8);
    }
  }
}
