.testimonials-section {
  padding-top: ac(62px, 32px);
  padding-bottom: ac(100px, 40px);
  overflow: hidden;

  &__wrap {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: ac(412px, 335px);
    margin-right: 20px;
    margin-top: ac(8px, 0px);
  }

  &__title {
    max-width: 98%;

    &:not(:last-child) {
      margin-bottom: ac(30px, 16px);
    }
  }

  &__text {
    max-width: 90%;

    &:not(:last-child) {
      margin-bottom: ac(30px, 28px);
    }
  }

  .slider-buttons {
    .slider-btn {
      margin-top: 0;
      margin-bottom: 28px;
    }
  }

  &__slider {
    width: 100%;
    max-width: ac(412px, 320px);
    margin-left: 0;
    overflow: visible;
    user-select: none;
  }

  &__item {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    min-height: ac(326px, 300px);
    transition: all 0.3s ease;
    background: var(--cl-white);
    box-shadow: 0px 4px 20px 5px rgba(0, 0, 0, 0.05);
    padding: ac(30px, 20px);
  }

  @mixin min-media 1921 {
    .swiper-slide:not(.swiper-slide-next):not(.swiper-slide-prev):not(.swiper-slide-active):not(.swiper-slide-next + .swiper-slide) {
      opacity: 0 !important;
    }
  }

  &__item-top {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 20px;
  }

  &__item-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--cl-dark-grey);
    width: ac(50px, 44px);
    min-width: ac(50px, 44px);
    height: ac(50px, 44px);
    margin-right: ac(18px, 12px);
    padding: ac(9px, 8px);
    border-radius: 50%;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__item-top-content {
    flex: 1;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    grid-row-gap: ac(10px, 8px);
  }

  &__item-name {
    font-size: ac(16px, 15px);
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  &__item-text {
    width: 100%;
    transition: max-height .4s ease;

    p,
    li {
      line-height: 1.44;
    }

    .testimonials-section__item-scroll {
      pointer-events: none;
      width: 100%;
      padding-right: 8px;
      max-height: calc((ac(18px, 16px) * 1.44) * 6);

      &[data-simplebar] {
        max-height: calc((ac(18px, 16px) * 1.44) * 10);
      }

      .simplebar-track.simplebar-vertical {
        display: none;
        width: 3px;
        background: lightgrey;
        border-radius: 0;
        opacity: 1;
        transition: opacity .2s ease;

        .simplebar-scrollbar {
          background: var(--cl-red);
          border-radius: 0;

          &:before {
            content: none;
          }
        }
      }
    }

    &.active {
      .testimonials-section__item-scroll {
        pointer-events: auto;

        .simplebar-track.simplebar-vertical {
          display: block;
        }
      }
    }

    &:not(.active) {
      /*@mixin max-line-length 6;

      @mixin max-sm {
        @mixin max-line-length 7;
      }*/

      .testimonials-section__item-scroll {

        .simplebar-content {
          @mixin max-line-length 6;

          /*@mixin max-sm {
            @mixin max-line-length 6;
          }*/
        }
      }
    }
  }

  &__item-btn {
    margin-top: 28px;
    display: none;
    justify-content: center;
    align-items: center;
    background: var(--cl-red);
    clip-path: inset(0 0 0 0 round 50%);
    transition: background-color 0.3s ease;
    margin-left: auto;
    width: 20px;
    height: 20px;
    cursor: pointer;
    transform: scale(1.1);

    i {
      font-size: 8px;
      color: var(--cl-light-grey);
      transition: opacity 0.3s ease;

      &.icon-minus {
        display: none;
      }
    }

    &:hover {
      @mixin min-media 652 {
        background-color: var(--cl-dark-grey);
      }
    }

    &.active {
      i {
        &.icon-minus {
          display: flex;
        }

        &.icon-plus {
          display: none;
        }
      }
    }
  }

  @mixin max-lg {
    &__content {
      width: 200px;
    }
  }

  @mixin max-md {
    &__wrap {
      flex-direction: column;
    }

    &__content {
      width: 100%;
      margin-right: 0;
    }

    &__text {
      max-width: 100%;
    }

    &__slider {
      max-width: 293px;
    }
  }
}
