.fp-watermark {
  display: none;
}

html {
  scrollbar-width: thin;
  scrollbar-color: var(--cl-red) transparent;
}

body {
  position: relative;
  min-width: 320px;
  margin: auto;
  background: var(--cl-white);

  color: var(--cl-white);
  font-family: var(--font-main);

  overflow-x: hidden;

  min-height: calc(100 * var(--vh));
  display: flex;

  flex-direction: column;
  align-items: stretch;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: var(--cl-light-grey);
    border-radius: 0;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--cl-red);
    border-radius: 0;
  }
}

.swiper-wrapper {
  left: 0 !important;
}

#site {
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  @mixin media 551 {
    overflow: hidden;
  }
}

main {
  flex-grow: 1;
}

main {
  min-width: 100vw;
}

.cont {
  margin: 0 auto;
  max-width: 1276px;
  width: perc(1276);

  @mixin max-lg {
    width: 89.33%;
  }
}

section,
header,
footer {
  position: relative;
  max-width: 100vw;
}

.logo {
  transition: 0.3s ease;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &:hover {
    transform: scale(1.06);
  }
}

#bold-credits {
  width: 150px;
  transition: ease-in-out 0.25s;

  &::before,
  &::after {
    display: none;
  }

  &:hover {
    transform: scale(1.1);
  }
}

.line-decor {
  height: 6px;
  position: relative;
  display: flex;

  &:before {
    content: '';
    background: var(--cl-light-grey);
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    transition: width 0.6s ease, background-color 0.25s ease, opacity 0.25s ease;
    transition-delay: 0.1s;
    opacity: 0;
    width: 0;
  }

  &.animated {
    width: 88px;

    &:before {
      width: 100%;
      opacity: 1;
    }
  }

  &.red-line {
    &:before {
      background-color: var(--cl-red);
    }
  }
}

#site.cookie-terms-page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;

  .main {
    flex-grow: 1;
    padding: 0;
  }
}

.cookie-terms-section {
  padding: ac(250px, 140px) 0 ac(150px, 50px);
  p {
    padding: 0;
    margin-bottom: 10px;
  }
  ul,
  ol {
    padding-left: 20px;
    li {
      p {
        margin-bottom: 0;
      }
    }
  }

  ul {
    list-style: circle;
  }

  ol {
    list-style: decimal;
  }

  a {
    display: inline;
    text-decoration: underline;
    transition: 0.2s;

    &:hover {
      opacity: 0.7;
    }
  }
}

.single-page-404 {
  min-height: 100vh;
  padding: 20px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1,
  p {
    margin-bottom: 15px;
    padding: 0;
  }

  a {
    display: inline-block;
    margin-top: 20px;
  }
}

.grecaptcha-badge {
  z-index: 200;
}
