.btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--cl-light-grey);
  border-radius: 25px;
  padding: 3px;
  min-width: 193px;
  cursor: pointer;
  border: 2px solid var(--cl-light-grey);
  transition: background-color 0.3s ease, transform 0.3s ease,
    box-shadow 0.3s ease;

  &__text {
    font-family: var(--font-main);
    font-size: ac(15px, 16px);
    font-weight: 600;
    line-height: normal;
    padding-right: 10px;
    padding-left: 25px;
    color: var(--cl-grey);
    transition: color 0.3s ease;
  }

  &__icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--cl-grey);
    transition: background-color 0.3s ease;

    i {
      font-size: 16px;
      color: var(--cl-white);
      transition: transform 0.3s ease, color 0.3s ease;
    }
  }

  &:hover {
    background-color: var(--cl-grey);

    .btn {
      &__text {
        color: var(--cl-light-grey);
      }

      &__icon {
        background-color: var(--cl-red);

        i {
          transform: rotate(-30deg) scale(1.2);
        }
      }
    }
  }

  &:active {
    transform: scale(0.9);
  }

  &:focus {
    outline: none !important;
  }

  &.btn-white {
    background-color: var(--cl-white);

    &:hover {
      background-color: var(--cl-grey);
    }
  }
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: ac(20px, 16px);

  @mixin media 551 {
    width: 100%;
    .btn {
      width: 100%;
      /*max-width: 335px;*/
    }
  }
}

.slider-btn {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--cl-grey);
  transition: background-color 0.3s ease, opacity 0.3s ease;
  cursor: pointer;

  i {
    font-size: 16px;
    color: var(--cl-white);
    transition: transform 0.3s ease, color 0.3s ease;
  }

  &.swiper-button-disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &:hover {
    &:not(.swiper-button-disabled) {
      background: var(--cl-red);
      color: var(--cl-light-grey);
    }
  }

  &.swiper-button-lock {
    display: none;
  }
}

.slider-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;

  .slider-btn {
    margin-top: ac(30px, 28px);
  }
}

.social-link {
  font-size: 24px;
  cursor: pointer;
  transition: color 0.3s ease;
  color: var(--cl-light-grey);

  &:hover {
    color: var(--cl-red);
  }
}

.social-links {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: ac(20px, 14px);
}

a {
  outline: none !important;
  &:active {
    outline: none !important;
  }
  &:focus {
    outline: none !important;
  }
}

.filters-btn {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;

  &__content {
    font-size: ac(26px, 24px);
    font-family: var(--font-main);
    font-weight: 500;
    color: var(--cl-grey);
  }

  &__icon {
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    i {
      font-size: 16px;
      color: var(--cl-grey);
      transition: all 0.3s ease;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);

      &.icon-close {
        opacity: 0;
        color: var(--cl-red);
      }
    }
  }

  &.active {
    .filters-btn__icon {
      i.icon-close {
        opacity: 1;
      }

      i.icon-filters {
        opacity: 0;
      }
    }
  }
}

.pagination {
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  &__list {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-column-gap: ac(10px, 6px);
  }

  i {
    font-size: 20px;
  }

  &__item {
    font-family: var(--font-main);
    font-size: ac(16px, 14px);
    font-weight: 600;
    line-height: 140%;
    display: flex;
    justify-content: center;
    align-items: stretch;
    width: ac(40px, 30px);
    height: ac(40px, 30px);
    border: 2px solid transparent;
    transition: all 0.25s ease;
    cursor: pointer;

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
    }

    a,
    span {
      font: inherit;
    }

    &.active {
      border-color: var(--cl-dark-grey);
      cursor: not-allowed !important;
    }

    &:not(.active) {
      &:hover {
        border-color: var(--cl-light-grey);
      }
    }

    &.with-points {
      pointer-events: none;
      margin-left: ac(-10px, -6px);
      margin-right: ac(-10px, -6px);

      .pagination__num {
        transform: translateY(4px);
      }
    }
  }

  &__item-btn {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: ac(40px, 38px);
    height: ac(40px, 38px);
    transition: all 0.25s ease;
    background-color: transparent;
    border-radius: 50%;
    cursor: pointer;

    &:hover {
      background-color: var(--cl-red);
      color: var(--cl-white);
    }
  }
}
