.similar-jobs-section {
  padding-top: ac(100px, 40px);
  padding-bottom: ac(100px, 40px);
  overflow: hidden;

  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
  }

  &__title {
    font-size: ac(30px, 26px);
    font-weight: 500;
    line-height: normal;

    &:not(:last-child) {
      margin-bottom: ac(42px, 32px);
    }

    @mixin media 690 {
      max-width: calc(100% - 110px);
    }
  }

  &__slider {
    width: 100%;
    max-width: 100%;
    overflow: visible;
  }
}
