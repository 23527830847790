.job-search-section {
  padding-top: ac(89px, 82px);
  padding-bottom: ac(130px, 50px);
  overflow: visible;

  &__wrap {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
  }

  &__container {
    display: flex;
    justify-content: space-between;
    position: relative;
    width: 100%;
  }

  &__sidebar {
    transform: translateY(ac(30px, 0px));
    flex: 1;
    max-width: 32.29%;
  }

  &__sidebar-item {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-bottom: ac(47px, 24px);
    border-bottom: ac(3px, 2px) solid var(--cl-dark-grey);

    &:not(:last-child) {
      margin-bottom: ac(50px, 22px);
    }

    &.active {
      .job-search-section__sidebar-item-top {
        i {
          transform: scale(1, -1);
        }
      }

      .job-search-section__sidebar-item-content {
        opacity: 1;
        margin-top: ac(31px, 24px);
        max-height: 616px;
      }
    }
  }

  &__sidebar-item-top {
    cursor: pointer;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    user-select: none;

    i {
      font-size: 20px;
      color: var(--cl-grey);
      transition: transform 0.3s ease;
      transform: scale(1, 1);
    }
  }

  &__sidebar-item-title {
    font-size: ac(30px, 22px);
    line-height: normal;
    font-weight: 500;
    max-width: calc(100% - 40px);
  }

  &__sidebar-item-content {
    opacity: 0;
    margin-top: 0;
    transition: max-height 0.4s ease, margin-top 0.3s ease, opacity 0.3s ease;
    max-height: 0;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    grid-column-gap: 10px;
    grid-row-gap: 9px;
    overflow: hidden;
    padding-right: 8px;
  }

  &__sidebar-item-scroll-container {
    max-height: 616px;
    width: 100%;
    padding-right: 10px;

    .simplebar-content {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-wrap: wrap;
      grid-column-gap: 10px;
      grid-row-gap: ac(9px, 10px);


      > *:first-child {
        margin-left: -10px;
      }
    }

    .simplebar-track.simplebar-vertical {
      width: 4px;
      background: var(--pp-light-gray);
      border-radius: 4px;

      .simplebar-scrollbar {
        background: var(--cl-red);
        border-radius: 4px;

        &:before {
          content: none;
        }
      }
    }

    @mixin media 639 {
      max-height: 256px;
    }
  }

  &__sidebar-item-subtitle {
    width: 100%;
    font-size: ac(18px, 17px);
    line-height: normal;
    font-weight: 500;

    &:not(:last-child) {
      padding-bottom: 5px;
    }
  }

  &__sidebar-item-additional {
    padding-top: 19px;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    grid-column-gap: 21px;
    grid-row-gap: 9px;
    margin-bottom: -2px;
  }

  &__result {
    width: 57.68%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
  }

  &__result-grid {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    gap: 10px;
  }

  &__filters-btn {
    display: none;
  }

  @mixin max-xl {
    &__sidebar {
      max-width: 38%;
    }
  }

  @mixin max-md {
    &__sidebar {
      max-width: 44%;
    }

    &__result {
      width: 50%;
    }
  }

  @mixin max-sm {
    overflow: hidden;

    &__sidebar {
      position: absolute;
      left: 0;
      top: 0;
      transform: translateX(-100%);
      opacity: 0;
      max-width: 314px;
      z-index: 2;
      transition: all 0.4s ease;

      @mixin media 390 {
        max-width: 100%;
      }
    }

    &__result {
      width: 100%;
      transition: all 0.3s ease;
      transform: translateX(0);
      opacity: 1;
    }

    &__filters-btn {
      display: flex;
      margin-right: auto;
      margin-bottom: 24px;
    }

    &__container {
      &.active {
        .job-search-section__sidebar {
          position: relative;
          opacity: 1;
          transform: translateX(0);
        }

        .job-search-section__result {
          position: absolute;
          left: 0;
          top: 0;
          transform: translateX(340px);
          opacity: 0.5;

          & > * {
            pointer-events: none;
          }

          @mixin media 390 {
            transform: translateX(110%);
          }
        }
      }
    }
  }
}
