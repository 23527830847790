.first-section {
  padding-top: ac(258px, 200px);
  padding-bottom: ac(117px, 40px);
  overflow: hidden;
  margin-bottom: ac(92px, 0px);

  @mixin media 1026 {
    margin-bottom: ac(60px, 0px);
  }

  &:before {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    bottom: 0;
    width: 58.68%;
    background: var(--cl-light-grey);
  }

  &__wrap {
    position: relative;
    z-index: 2;
  }

  &__content {
    width: 100%;
    background: var(--cl-medium-grey);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: ac(50px, 36px) ac(40px, 26px) ac(50px, 48px);
    max-width: ac(628px, 455px);
    position: relative;
    z-index: 2;
  }

  &__title {
    &:not(:last-child) {
      margin-bottom: ac(18px, 14px);
    }
  }

  &__subtitle {
    &:not(:last-child) {
      margin-bottom: ac(26px, 18px);
    }
  }

  &__text {
    max-width: ac(480px, 420px);
    &:not(:last-child) {
      margin-bottom: ac(40px, 26px);
    }

    h4, .title-h4 {
      font-size: ac(20px, 18px);
      /*letter-spacing: -0.11px;*/
      font-weight: 400;

      @mixin sm {
        line-height: normal;
      }

      @mixin max-sm {
        letter-spacing: normal;
      }
    }
  }

  &__buttons {
  }

  &__image {
    position: absolute;
    left: ac(323px, 100px);
    right: -82px;
    top: -67px;
    bottom: -67px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &:after {
      position: absolute;
      content: '';
      top: 0;
      bottom: 0;
      left: ac(844px, 460px);
      background: var(--cl-white);
      width: 20px;
      z-index: 1;
    }
  }
}

@mixin max-sm {
  .first-section {
    &:before {
      width: calc(100% - 25.6vw);
    }

    &:after {
      bottom: 0;
      left: 0;
      right: 0;
      height: 97px;
      background-color: var(--cl-light-grey);
      position: absolute;
      content: '';
    }

    &__wrap {
      position: static;
    }

    &__content {
      max-width: 100%;
    }

    &__text {
      max-width: 100%;
    }

    &__image {
      top: 80px;
      left: 0;
      right: 0;
      bottom: 96px;

      &:after {
        top: auto;
        bottom: 112px;
        left: 0;
        background: var(--cl-white);
        width: 100%;
        height: 20px;
      }
    }
  }
}
