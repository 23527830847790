.footer {
  padding-top: ac(60px, 48px);
  padding-bottom: ac(60px, 32px);
  background: var(--cl-medium-grey);

  &__wrap {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    position: relative;
  }

  &__logo {
    width: 196px;
    @mixin aspect-ratio 655.99, 161.12;
    transition: transform 0.3s ease, opacity 0.3s ease;
    margin-right: ac(128px, 50px);
    overflow: visible;

    img,
    svg {
      object-fit: contain;
      object-position: left;
    }

    svg {
      path {
        transition: fill 0.3s ease;
      }
    }

    &:hover {
      transform: scale(1.03);

      svg {
        path {
          fill: var(--cl-light-grey);

          &.red-color {
            fill: var(--cl-red);
          }
        }
      }
    }
  }

  &__nav {
    margin-top: 4px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    grid-column-gap: 20px;
    grid-row-gap: 26px;
    max-width: ac(902px, 400px);
  }

  &__links-grid {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-column-gap: 20px;
    grid-row-gap: 25px;
    max-width: ac(628px, 200px);

    .footer__links {
      min-width: 100% !important;
      width: 100%;
    }

    .footer__links-list {
      max-width: 100%;
    }

    .footer__link {
      max-width: 256px;
      width: 100%;
      margin-right: 44px;
    }
  }

  &__links {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    &:first-child {
      min-width: 304px;
    }

    &.w-full {
      @mixin sm {
        padding-bottom: 4px;

        .footer__links-list {
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          gap: 30px;
        }
      }
    }

    &.with-socials {
      @mixin md {
        position: absolute;
        left: 0;
        top: ac(96px, 86px);
      }
    }
  }

  &__links-title {
    font-size: 18px;
    font-weight: 500;
    line-height: normal;
    color: var(--cl-white);
    margin-bottom: ac(15px, 18px);

    @mixin max-sm {
      font-size: 20px;
      line-height: 160%;
    }
  }

  &__links-list {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    grid-column-gap: 20px;
    grid-row-gap: 10px;

    &.grid-type {
      grid-template-columns: repeat(2, minmax(0, 1fr));
      min-width: 196px;
    }

    @mixin max-sm {
      grid-column-gap: 30px;
    }
  }

  &__link {
    font-family: var(--font-main);
    font-size: 18px;
    font-weight: 400;
    line-height: normal;
    color: var(--cl-light-grey);
    transition: color 0.3s ease, text-decoration-color 0.3s ease;
    width: max-content;
    position: relative;

    &:before {
      position: absolute;
      right: 0;
      bottom: 0;
      height: 2px;
      background: var(--cl-red);
      opacity: 0;
      width: 0;
      transition: opacity 0.3s ease, width 0.3s ease;
      content: "";
    }

    &:hover {
      color: var(--cl-red);
      &:before {
        opacity: 1;
        right: auto;
        left: 0;
        width: 100%;
      }
    }

    &.not-before {
      &:before {
        content: none;
      }
    }

    @mixin max-sm {
      line-height: 155.5%;
    }
  }

  &__bottom {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: ac(30px, 26px);
    gap: 12px;
    flex-wrap: wrap;
  }

  &__bottom-text {
    font-size: ac(16px, 14px);
  }

  @mixin max-lg {
    &__links-grid {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
  }

  @mixin media 951 {
    &__links-grid {
      max-width: 100%;
      grid-template-columns: repeat(2, minmax(0, 1fr));

      .footer__links {
        &:nth-child(3) {
          grid-column: 1 / -1;
        }
      }
    }
  }

  @mixin max-md {
    &__nav {
      margin-top: 24px;
      max-width: 100%;
      width: 100%;
    }
  }

  @mixin max-sm {
    &__links-list {
      &.grid-type {
        min-width: 170px;
        grid-row-gap: 5px;
        margin-right: 5px;
      }
    }

    &__social-list {
      margin-left: 2px;

      .social-link {
        font-size: 26px;
      }
    }

    &__bottom {
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column-reverse;
    }
  }

  @mixin media 390 {
    &__links-grid {
      max-width: 100%;
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
  }
}
