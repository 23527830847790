.specialised-section {
  padding-top: ac(100px, 40px);
  padding-bottom: ac(100px, 30px);
  overflow: hidden;

  &__wrap {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: ac(412px, 335px);
    margin-right: 20px;
    margin-top: ac(28px, 0px);
    position: relative;
    z-index: 10;
  }

  &__title {
    max-width: 98%;

    &:not(:last-child) {
      margin-bottom: ac(30px, 16px);
    }
  }

  &__text {
    max-width: 90%;

    &:not(:last-child) {
      margin-bottom: ac(30px, 28px);
    }
  }

  .slider-buttons {
    .slider-btn {
      margin-top: 0;
      margin-bottom: 28px;
    }
  }

  &__slider {
    width: 100%;
    max-width: ac(412px, 320px);
    margin-left: 0;
    overflow: visible;
    clip-path: inset(-20px -700px -20px -10px);
  }

  &__item-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    min-height: ac(580px, 460px);
    padding: ac(81px, 64px) ac(15px, 24px) ac(35px, 40px) ac(35px, 24px);
    transition: transform 0.3s ease;
    transform: scale(1) translateX(0);
    transform-origin: left;

    &:hover {
      .specialised-section__item-image {
        img {
          transform: scale(1.05);
        }

        &:after {
          opacity: 0.6;
        }
      }

      .line-decor {
        width: 120px;
      }
    }
  }

  &__item {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;
    min-height: ac(580px, 460px);
    transition: all 0.3s ease;
    overflow: visible !important;
    height: auto;
    pointer-events: none;

    &.swiper-slide-prev {
      opacity: 0.2;
    }

    &.swiper-slide-active {
      pointer-events: auto;
      .specialised-section__item-image {
        &:after {
          opacity: 0.5;
        }
      }

      .specialised-section__item-text {
        opacity: 1;
      }

      .specialised-section__item-info {
        opacity: 1;
      }
    }

    &.swiper-slide-next {
      pointer-events: auto;

      + .specialised-section__item {
        pointer-events: auto;
      }
    }

    .line-decor {
      position: absolute;
      left: 0;
      top: ac(60px, 48px);
      width: 88px;
      transition: width 0.33s ease;

      &:before {
        width: 100%;
        opacity: 1;
      }
    }

    &.swiper-slide-next {
      /*scale: 0.8;
      transform-origin: left;*/

      + .swiper-slide {
        /*scale: 0.8;
        transform-origin: left;*/
        z-index: 10 !important;
      }

      .specialised-section__item-container {
        transform: scale(0.74);
        transform-origin: left;

        @mixin max-sm {
          transform: scale(0.76) translateX(-2%);
        }
      }

      .specialised-section__item-title {
        transform-origin: 16%;
        transform: scale(0.7);
      }

      + .swiper-slide {
        .specialised-section__item-container {
          transform: scale(0.74) translateX(-35%);
          transform-origin: left;

          @mixin max-sm {
            transform: scale(0.76);
          }
        }

        .specialised-section__item-title {
          transform-origin: 16%;
          transform: scale(0.7);
        }
      }
    }

    /*&:nth-child(3) {
      .specialised-section__item-title {
        font-size: ac(52px, 32px);
        letter-spacing: -1px;
      }
    }*/
  }

  &__item-image {
    position: absolute;
    inset: 0;
    z-index: -1;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: transform 0.3s ease;
    }

    &:after {
      position: absolute;
      content: '';
      inset: 0;
      background-color: var(--cl-grey);
      opacity: 0.7;
      transition: opacity 0.3s ease;
      z-index: 1;
    }
  }

  &__item-title {
    font-size: ac(56px, 32px);
    line-height: 1.1;
    font-weight: 600;
    transition: all 0.3s ease;
    transform: scale(1);
    letter-spacing: -0.5px;

    &:not(:last-child) {
      margin-bottom: ac(24px, 18px);
    }

   /* @mixin max-sm {
      line-height: 127%;
    }*/
  }

  &__item-text {
    opacity: 0;
    transition: opacity 0.3s ease;
    max-width: calc(100% - ac(15px, 0px));
    max-height: calc((ac(20px, 18px) * 1.4) * 6.1);
    width: 100%;
    /*overflow-y: auto;*/
    /*padding-right: 10px;*/

    .simplebar-track.simplebar-vertical {
      width: ac(4px, 3px);
      background: var(--cl-medium-grey);
      border-radius: 2px;
      transform: translateX(ac(6px, 4px));
      .simplebar-scrollbar {
        background: var(--cl-red);
        border-radius: 4px;
        &:before {
          content: none;
        }
      }
    }

    /*scrollbar-width: thin;
    scrollbar-color: var(--cl-red) transparent;
    &::-webkit-scrollbar {
      width: 3px;
    }

    &::-webkit-scrollbar-track {
      background: var(--cl-light-grey);
      border-radius: 0;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--cl-red);
      border-radius: 0;
    }*/

    &:not(:last-child) {
      margin-bottom: ac(20px, 16px);
    }

    @mixin max-sm {
      max-height: calc((ac(20px, 18px) * 1.55) * 5.1);
    }
  }

  &__item-info {
    margin-top: auto;
    font-size: ac(25px, 22px);
    line-height: normal;
    font-weight: 500;
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  &.hiring-page {
    @mixin lg {
      .specialised-section__item-title {
        font-size: ac(50px, 32px);
      }

      .specialised-section__item-text {
        max-width: calc(100% - ac(25px, 0px));
      }
    }
  }
}

/*.is-safari {
  .specialised-section {
    &__item-text {
      &::-webkit-scrollbar {
        width: initial;
      }

      &::-webkit-scrollbar-track {
        background: initial;
        border-radius: initial;
      }

      &::-webkit-scrollbar-thumb {
        background: initial;
        border-radius: initial;
      }
    }
  }
}*/

@mixin max-md {
  .specialised-section {
    &__wrap {
      flex-direction: column;
    }

    &__content {
      width: 100%;
      margin-right: 0;
    }

    &__text {
      max-width: 100%;
    }

    &__slider {
      max-width: 293px;
    }

    &__item {
      min-height: 400px;
    }

    &__item-container {
      min-height: 400px;
    }
  }
}
