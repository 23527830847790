.talk-section {
  margin-top: ac(100px, 0px);
  padding-top: ac(120px, 48px);
  padding-bottom: ac(50px, 0px);
  overflow: hidden;
  position: relative;

  &:before {
    bottom: 0;
    left: 0;
    right: 0;
    height: 330px;
    position: absolute;
    content: '';
    background: var(--cl-medium-grey);
  }

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: ac(1142px, 700px);

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__wrap {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    position: relative;
    z-index: 2;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 32.29%;

    .line-decor {
      margin-bottom: ac(15px, 8px);

      &:before {
        transition-delay: 0.6s !important;
      }
    }
  }

  &__form {
    width: 66.14%;
    padding: ac(51px, 26px) ac(108px, 26px) ac(50px, 46px);
    background: var(--cl-grey);
  }

  &__form-grid {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-column-gap: ac(20px, 18px);
    grid-row-gap: ac(25px, 20px);

    @mixin media 951 {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
  }

  &__buttons {
    margin-top: ac(30px, 40px);
    width: 100%;
    justify-content: flex-end;
  }
}

@mixin max-sm {
  .talk-section {
    &__wrap {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    }

    &__content {
      width: 100%;
      margin-bottom: 25px;
    }

    &__title {
      font-size: 37px;
    }

    &__form {
      width: 100%;
    }

    &__image {
      bottom: 98px;
    }
  }
}
