.team-card {
  position: relative;
  z-index: 1;
  cursor: pointer;

  &__image {
    @mixin aspect-ratio 412, 400;
    width: 100%;
    clip-path: inset(0 0 0 0);

    img {
      transition: filter 0.3s ease, transform 0.3s ease;
      filter: grayscale(100%);
    }

    &:after {
      position: absolute;
      inset: 0;
      z-index: 1;
      content: '';
      background: #e4ecf1;
      mix-blend-mode: multiply;
    }

    &.is-active {
      img {
        filter: grayscale(0%);
      }
    }
  }

  &__socials {
    top: ac(25px, 20px);
    right: ac(25px, 20px);
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: ac(15px, 12px);
  }

  &__social {
    display: flex;
    justify-content: center;
    align-items: center;
    clip-path: inset(0 0 0 0 round 50%);
    background: var(--cl-red);
    transition: background-color 0.3s ease;
    cursor: pointer;

    i {
      font-size: ac(44px, 42px);
      color: var(--cl-light-grey);
    }

    &:hover {
      background-color: var(--cl-dark-grey);
    }
  }

  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: ac(30px, 24px);
    grid-row-gap: ac(20px, 14px);
  }

  &__name {
    font-size: ac(50px, 25px);
    font-weight: 500;
    line-height: normal;
  }

  &__position {
    font-size: ac(30px, 18px);
    font-weight: 500;
    line-height: normal;
    color: var(--cl-medium-grey);
  }

  &:hover {
    img {
      transform: scale(1.03);
      filter: grayscale(0);
    }
  }
}
