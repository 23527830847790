.services-section {
  padding-top: ac(100px, 40px);
  padding-bottom: ac(100px, 40px);
  overflow: hidden;

  &__wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: ac(64px, 32px);
    padding-bottom: ac(64px, 32px);
    position: relative;
  }

  &__content {
    width: 83.07%;
    margin-left: auto;
    margin-right: auto;
    background: var(--cl-white);
    box-shadow: 0px 4px 20px 5px rgba(0, 0, 0, 0.05);
    padding-top: ac(60px, 30px);
    padding-bottom: ac(60px, 30px);
    padding-left: ac(50px, 20px);
    padding-right: ac(50px, 20px);
    position: relative;
    z-index: 1;
  }

  &__title {
    &:not(:last-child) {
      margin-bottom: ac(51px, 30px);
    }
  }

  &__tabs-container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  &__tabs-buttons {
    width: 26.04%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    grid-row-gap: ac(40px, 20px);
  }

  &__tabs-btn {
    padding-bottom: ac(26px, 16px);
    position: relative;
    font-size: ac(30px, 20px);
    font-weight: 500;
    line-height: normal;
    transition: color 0.3s ease, opacity 0.3s ease;
    color: var(--cl-medium-grey);

    cursor: pointer;

    &.active {
      color: var(--cl-red);
    }

    &:before {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      height: ac(6px, 4px);
      background: var(--cl-dark-grey);
      transition: all 0.3s ease;
      width: 0;
    }

    &.active {
      &:before {
        width: ac(88px, 60px);
        background-color: var(--cl-red);
      }
    }

    &:hover {
      &:not(.active) {
        color: var(--cl-red);
        opacity: 0.7;
      }
    }
  }

  &__tabs-items {
    width: 71.46%;
    max-width: 628px;
    position: relative;
    overflow: hidden;
    transform: translateX(ac(-58px, 0px));

    @mixin media 1400 {
      transform: none;
    }
  }

  &__tabs-item {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    opacity: 0;
    transition: opacity 0.3s ease;

    &.active {
      position: relative;
      left: auto;
      top: auto;
      opacity: 1;
    }
  }

  &__images {
    position: absolute;
    width: 44.1%;
    top: 0;
    bottom: 0;
    right: -88px;

    @mixin min-media 1441 {
      left: 794px;
      width: auto;
      right: calc((-1) * (100vw - 1276px) / 2);
    }
  }

  &__image {
    width: 100%;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    transform: translateX(50%);
    opacity: 0;
    transition: all 0.4s ease;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &.active {
      position: relative;
      opacity: 1;
      transform: translateX(0);
    }
  }

  @mixin max-xl {
    &__content {
      width: 88%;
    }
  }

  @mixin max-lg {
    &__content {
      width: 95%;
    }
  }

  @mixin media 901 {
    &__content {
      width: 100%;
    }

    &__images {
      width: 50%;
    }
  }

  @mixin max-sm {
    &__images {
      right: -10vw;
      width: 80%;
    }

    &__tabs-container {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    }

    &__tabs-buttons {
      margin-bottom: 20px;
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      flex-wrap: wrap;
      gap: 20px;
    }

    &__tabs-items {
      width: 100%;
    }
  }

  @mixin media 425 {
    &__images {
      right: -10vw;
      width: 90%;
    }
  }

  @mixin max-xs {
    &__images {
      width: 95%;
    }
  }
}
