@import url('https://fonts.googleapis.com/css2?family=Instrument+Sans:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');

:root {
  --cl-black: #000000;
  --cl-black-rgb: 0 0 0;

  --cl-blue: #3ab4ff;
  --cl-blue-rgb: 58 180 255;

  --cl-dark-blue: #0e1127;
  --cl-dark-blue-rgb: 14 17 39;

  --cl-white: #ffffff;
  --cl-white-rgb: 255 255 255;

  --cl-light-grey: #e4ecf1;

  --cl-medium-grey: #8498a0;

  --cl-grey: #606470;
  --cl-grey-rgb: 160 174 192;

  --cl-dark-grey: #52525b;
  --cl-dark-grey-rgb: 82 82 91;

  --cl-red: #a90506;
  --cl-red-rgb: 239 68 68;

  --cl-primary: #ec612a;
  --cl-primary-rgb: 236 97 42;

  --font-main: 'Instrument Sans', sans-serif;

  /* --mobile-menu-start-point and @custom-media --mobile-menu-start-point must be the same */
  --mobile-menu-start-point: 1200;

  --vh: calc(100vh / 100);
}

/* --mobile-menu-start-point and @custom-media --mobile-menu-start-point must be the same */
@custom-media --mobile-menu-start-point (max-width: 1200px);

/* --mobile-menu-end-point must be greater than 1 of the  --mobile-menu-start-point and @custom-media --mobile-menu-start-point */
@custom-media --mobile-menu-end-point (min-width: 1201px);

