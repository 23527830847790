.select-sectors-section {
  padding-top: ac(60px, 36px);
  padding-bottom: ac(60px, 36px);
  background: var(--cl-light-grey);

  &__wrap {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &__title {
    width: 100%;

    &:not(:last-child) {
      margin-bottom: ac(40px, 24px);
    }
  }

  &__text {
    width: 23.82%;

    p,
    li {
      line-height: 1.4;
      font-size: ac(20px, 16px);
      font-weight: 500;
      color: var(--cl-grey);
    }
  }

  &__list {
    width: 66.14%;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-column-gap: ac(32px, 20px);
    grid-row-gap: ac(38px, 28px);
    padding-right: ac(10px, 0px);
  }

  &__btn {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    cursor: pointer;

    i {
      margin-top: ac(13.5px, 10px);
      font-size: 16px;
      color: var(--cl-dark-grey);
      transition: transform 0.5s ease, color 0.3s ease;
    }

    &:hover {
      i {
        transform: translateX(72px);
        color: var(--cl-red);
      }

      .line-decor {
        &.animated {
          &:before {
            width: 140%;
          }
        }
      }
    }
  }

  &__btn-text {
    width: 100%;
    line-height: normal;
    font-family: var(--font-main);
    font-size: ac(20px, 16px);
    font-weight: 600;
    padding-bottom: ac(39px, 20px);
    color: var(--cl-dark-grey);
  }

  @mixin max-sm {
    &__text {
      width: 100%;
      margin-bottom: 24px;
    }

    &__list {
      width: 100%;
    }
  }

  @mixin media 425 {
    &__list {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
  }
}
