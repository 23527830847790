.our-purpose-section {
  margin-top: ac(-232px, -90px);
  padding-bottom: ac(50px, 32px);
  overflow: hidden;

  &__wrap {
    padding-top: ac(254px, 100px);
    position: relative;
    z-index: 1;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: row-reverse;

    &:before {
      content: '';
      position: absolute;
      width: 74.61%;
      background: var(--cl-light-grey);
      top: 0;
      bottom: ac(58px, 28px);
      right: 0;
      z-index: -1;
    }
  }

  &__content {
    width: 49.27%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-right: ac(108px, 24px);
    padding-bottom: ac(118px, 64px);
  }

  &__title {
    &:not(:last-child) {
      margin-bottom: ac(30px, 20px);
    }
  }

  &__text {
    &:not(:last-child) {
      margin-bottom: ac(30px, 20px);
    }
  }

  &__images {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 43.57%;
    margin-top: auto;
  }

  &__image {
    @mixin aspect-ratio 260, 458;
    width: 46.76%;
  }

  @mixin max-lg {
    &__images {
      width: 48%;
    }

    &__wrap {
      &:before {
        width: 65%;
      }
    }
  }

  @mixin media 800 {
    &__wrap {
      &:before {
        width: 60%;
      }
    }
  }

  @mixin max-md {
    &__wrap {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      &:before {
        display: none;
      }
    }

    &__content {
      padding-top: 40px;
      width: 100%;
      padding-bottom: 32px;
      position: relative;
      z-index: 1;

      &:before {
        content: '';
        position: absolute;
        background: var(--cl-light-grey);
        top: -40px;
        bottom: -64px;
        right: -8vw;
        left: -8vw;
        z-index: -1;
      }
    }

    &__images {
      width: 90%;
      margin-left: auto;
      position: relative;
      z-index: 2;
    }
  }

  @mixin max-xs {
    &__images {
      width: 100%;
    }
  }
}
