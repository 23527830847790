.our-vision-section {
  padding-top: ac(53px, 24px);
  padding-bottom: ac(100px, 40px);
  overflow: hidden;

  &__wrap {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__content {
    width: 50.78%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-left: ac(108px, 0px);
  }

  &__title {
    &:not(:last-child) {
      margin-bottom: ac(30px, 20px);
    }
  }

  &__text {
    &:not(:last-child) {
      margin-bottom: ac(30px, 20px);
    }
  }

  &__image {
    @mixin aspect-ratio 520, 479;
    width: 40.75%;
  }

  @mixin max-md {
    &__wrap {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    }

    &__content {
      width: 100%;
      margin-bottom: 32px;
    }

    &__image {
      width: 90%;
      margin-right: auto;
    }
  }

  @mixin max-xs {
    &__image {
      width: 100%;
    }
  }
}
