.work-for-us-section {
  padding-top: ac(100px, 40px);
  padding-bottom: ac(63px, 34px);
  overflow: hidden;

  &__wrap {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    grid-column-gap: ac(10px, 20px);
  }

  &__content-container {
    flex: 1;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    z-index: 1;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      z-index: -1;
      background: var(--cl-medium-grey);
      top: 0;
      left: 0;
      bottom: 0;
      width: 110.19%;
    }
  }

  &__image {
    width: 42.83%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__content {
    padding-top: ac(50px, 24px);
    padding-bottom: ac(50px, 24px);
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    width: 42.83%;
    margin-right: ac(30px, 0px);
  }

  &__title {
    &:not(:last-child) {
      margin-bottom: ac(30px, 20px);
    }
  }

  &__text {
    &:not(:last-child) {
      margin-bottom: ac(30px, 20px);
    }
  }

  &__benefits {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    grid-row-gap: 10px;
    width: ac(304px, 240px);
  }

  &__benefit {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    background: var(--cl-white);
    box-shadow: 0px 4px 20px 5px rgba(0, 0, 0, 0.05);
    font-size: ac(16px, 14px);
    line-height: normal;
    font-weight: 600;
    color: var(--cl-red);
    padding: ac(20px, 16px);
  }

  @mixin max-xl {
    &__image {
      width: 49%;
    }
  }

  @mixin max-lg {
    &__image {
      width: 51%;
    }

    &__benefits {
      width: 200px;
    }
  }

  @mixin media 901 {
    &__wrap {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    }

    &__content-container {
      padding-right: 20px;
      align-items: flex-start;

      &:before {
        width: auto;
        bottom: -54px;
        left: -10vw;
        right: -10vw;
      }
    }

    &__image {
      @mixin aspect-ratio 412, 559;
    }

    &__benefits {
      width: 100%;
      margin-top: 24px;
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      grid-column-gap: 10px;
      grid-row-gap: 10px;
    }
  }

  @mixin max-sm {
    &__content-container {
      flex-direction: column-reverse;
      align-items: flex-start;
      justify-content: flex-start;
      padding-right: 0;
    }

    &__content {
      margin-bottom: 10px;
      width: 100%;
    }

    &__image {
      width: 100%;
      max-width: 412px;
      margin-right: auto;
    }
  }

  @mixin media 425 {
    &__image {
      width: 100%;
    }

    &__benefits {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    &__benefit {
      font-size: 16px;
    }
  }
}
