/*
Styles for popup background and basic styles for fonts
*/

.termsfeed-com---nb .cc-nb-main-container {
  padding-bottom: ac(50px, 24px);
  padding-top: ac(50px, 24px);
}

.termsfeed-com---palette-dark.termsfeed-com---nb {
  border: none;
  border-right: 0;
  border-bottom: 0;
  background: var(--cl-white);
  border-radius: 0;
  color: var(--cl-grey);
  font-family: var(--font-main);
  box-shadow: 0px 4px 12px 0px color-mod(var(--cl-medium-grey) a(50%));

  @mixin min-media 652 {
    margin-right: 10px;
    margin-bottom: 10px;
  }

  @mixin media 651 {
    margin-right: 20px;
    margin-bottom: 10px;
  }

  @mixin media 380 {
    margin-right: 0;
  }

  .cc-nb-okagree,
  .cc-nb-reject,
  .cc-nb-changep {
    margin-right: 0 !important;
    margin-bottom: 0 !important;
    margin-top: 0 !important;
    text-transform: uppercase;
  }

  .cc-nb-title,
  .cc-nb-text {
    color: var(--black);
  }

  #cc-nb-text {
    font-weight: 400;
  }
  #cc-nb-title {
    color: var(--cl-grey);
    font-family: var(--font-main);
  }
}

.termsfeed-com---nb {
  /*
    Styles for buttons
    */
  .cc-nb-okagree,
  .cc-nb-reject,
  .cc-nb-changep,
  .cc-cp-foot-button .cc-cp-foot-save {
    position: relative;
    overflow: hidden;
    cursor: pointer;
    text-align: center;
    width: fit-content;
    z-index: 2;
    color: var(--cl-grey);
    align-items: center;
    gap: 15px;
    text-transform: none !important;
    display: flex;
    justify-content: space-between;
    background: var(--cl-light-grey);
    border-radius: 25px;
    padding: 3px;
    min-width: 160px;
    border: 2px solid var(--cl-light-grey);
    transition: all .3s ease;
    font-family: var(--font-main);
    font-size: ac(15px, 16px);
    font-weight: 600;
    line-height: normal;
    padding-right: 10px;
    padding-left: 28px;
    height: 50px;

    &:focus {
      box-shadow: none !important;
    }

    &:after {
      content: "\e901";
      font-family: "icomoon";
      color: var(--cl-white);
      font-size: 16px;
      transition: all 0.3s ease-in-out;
      position: relative;
      z-index: 5;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--cl-grey);
      text-align: center;
      margin-right: -6px;
    }

    &:hover {
      background-color: var(--cl-grey);
      color: var(--cl-light-grey);

      &:after {
        background-color: var(--cl-red);
        color: var(--cl-light-grey);
        transform: rotate(-30deg) scale(1.05);
      }
    }
  }

  .cc-nb-buttons-container {
    display: flex !important;
    flex-wrap: wrap;
    grid-column-gap: ac(18px, 16px);
    grid-row-gap: 10px;
  }
}

.termsfeed-com---palette-dark .cc-cp-body-content-entry a {
  color: var(--cl-dark-grey);
  transition: all 0.3s ease;
  text-transform: none;
  font: inherit;

  &:hover {
    color: var(--cl-red);
    text-decoration-color: transparent;
  }
}

/*
Styles for popup with settings
*/
.termsfeed-com---palette-dark {
  .cc-pc-container {
    font-family: var(--font-main) !important;
    border: none;
    border-radius: 0;
    overflow: hidden;
    box-shadow: 0px 4px 12px 0px color-mod(var(--cl-medium-grey) a(50%));
  }

  .cc-pc-head,
  .cc-cp-body-content,
  .cc-cp-foot {
    background: var(--cl-white) !important;
    border-top-color: var(--cl-grey) !important;
    border-bottom-color: var(--cl-grey) !important;
  }

  .cc-cp-body-tabs {
    font-family: var(--font-main) !important;
  }

  .cc-cp-body-tabs {
    background-color: var(--cl-white);
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    gap: 3px;
    padding: 3px;
  }

  .cc-cp-body-tabs-item {
    background: var(--cl-light-grey);
    transition: all .3s ease;
    opacity: 1;
    border: none !important;
    border-radius: 0 !important;

    &:hover {
      opacity: 0.8;
      .cc-cp-body-tabs-item-link {
        color: var(--cl-red) !important;
      }
    }
  }

  .cc-cp-body-tabs-item-link {
    color: var(--cl-grey);
    transition: all .3s ease;

    &:focus {
      box-shadow: none;
    }


  }

  .cc-cp-body-tabs-item[active='true'] {
    background: var(--cl-medium-grey) !important;

    .cc-cp-body-tabs-item-link {
      color: var(--cl-red);
    }

    &:hover {
      opacity: 1;
    }
  }

  .cc-pc-head-lang {
    select {
      color: var(--cl-grey);
      padding: 5px;
      background: var(--cl-light-grey);
      cursor: pointer;

      &:focus {
        box-shadow: none;
      }

      option {
        font-size: ac(18px, 16px);
        font-weight: 400;
        line-height: ac(32px, 28px);
        font-style: normal;
        font-family: var(--font-main);
        cursor: pointer;
      }
    }
  }

  .cc-pc-head-close {
    color: var(--cl-red);
    transition: all 0.3s ease;

    &:hover {
      color: var(--cl-medium-grey);
      transform: scale(1.1);
    }

    &:focus {
      border: 0;
    }
  }
}

/*
Styles for the lower part of the popup with settings
*/
.termsfeed-com---pc-dialog {
  .cc-cp-body-tabs {
    font-family: var(--font-main) !important;
  }

  .cc-cp-foot {
    padding: 10px 0;
    flex-wrap: wrap;
  }

  .cc-cp-foot-byline {
    a {
      color: var(--cl-dark-grey);
      text-decoration: underline;
      transition: all 0.3s ease;

      &:hover {
        color: var(--cl-red);
        text-decoration-color: transparent;
      }
    }
  }

  .cc-cp-foot-button .cc-cp-foot-save {
    position: relative;
    overflow: hidden;
    cursor: pointer;
    text-align: center;
    width: fit-content;
    z-index: 2;
    color: var(--cl-grey);
    align-items: center;
    gap: 15px;
    text-transform: none !important;
    display: flex;
    justify-content: space-between;
    background: var(--cl-light-grey);
    border-radius: 25px;
    padding: 3px;
    min-width: 160px;
    border: 2px solid var(--cl-light-grey);
    transition: all .3s ease;
    font-family: var(--font-main);
    font-size: ac(15px, 16px);
    font-weight: 600;
    line-height: normal;
    padding-right: 10px;
    padding-left: 28px;
    height: 50px;

    &:focus {
      box-shadow: none !important;
    }

    &:after {
      content: "\e901";
      font-family: "icomoon";
      color: var(--cl-white);
      font-size: 16px;
      transition: all 0.3s ease-in-out;
      position: relative;
      z-index: 5;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--cl-grey);
      text-align: center;
      margin-right: -6px;
    }

    &:hover {
      background-color: var(--cl-grey);
      color: var(--cl-light-grey);

      &:after {
        background-color: var(--cl-red);
        color: var(--cl-light-grey);
        transform: rotate(-30deg) scale(1.05);
      }
    }

    @mixin media 530 {
      margin-bottom: 10px;
      margin-left: 10px;
    }
  }
}

.termsfeed-com---palette-dark .cc-cp-body-content-entry-text {
  a {
    display: inline-block;
  }
}

.termsfeed-com---palette-dark {

  .cc-cp-body-content-entry-text {
    color: var(--cl-grey);
    font-weight: 500;
  }

  .cc-cp-body-content-entry-title {
    color: var(--cl-grey);
  }

  .cc-pc-head-title-text {
    color: var(--cl-grey);
    font-weight: 500;
  }

  .cc-pc-head-title-headline {
    color: var(--cl-grey);
  }

  .cc-cp-foot-byline {
    color: var(--cl-grey);
    font-weight: 500;
  }
}

.cc-custom-checkbox {
  label {
    font-weight: 500;
  }
}

.termsfeed-com---pc-dialog input[type="checkbox"].cc-custom-checkbox + label {
  color: var(--cl-grey);

  &:after, &:before {
    border-color: var(--cl-red);
  }
}

.termsfeed-com---pc-dialog input[type="checkbox"].cc-custom-checkbox:checked + label::before {
  background: var(--cl-red);
}

/*
Styles for mobile adaptation
*/
@mixin media 651 {
  .termsfeed-com---nb-simple {
    max-width: 90%;
  }

  .termsfeed-com---nb .cc-nb-okagree,
  .termsfeed-com---nb .cc-nb-reject,
  .termsfeed-com---nb .cc-nb-changep {
    margin-top: 0 !important;
    max-width: 100%;
  }

  .termsfeed-com---nb .cc-nb-main-container {
    padding: 2rem;
  }
}

@mixin media 425 {
  .termsfeed-com---nb .cc-nb-okagree,
  .termsfeed-com---nb .cc-nb-reject,
  .termsfeed-com---nb .cc-nb-changep {
    min-width: auto;
    text-align: center !important;
    justify-content: center;
    font-size: 16px;
  }
}

@mixin media 380 {
  .termsfeed-com---nb-simple {
    max-width: 100%;
  }
}
