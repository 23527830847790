h1,
h2,
h3,
h4,
.title-h1, .title-h2, .title-h3, .title-h4 {
  font-weight: 500;
  line-height: 130%;
  color: var(--cl-grey);
}

h1, .title-h1 {
  font-size: ac(70px, 37px);
  line-height: normal;

  @mixin max-sm {
    line-height: 129%;
  }
}

h2, .title-h2 {
  font-size: ac(50px, 27px);
  line-height: normal;

  @mixin max-sm {
    line-height: 140%;
  }

  &.subtitle {
    font-size: ac(35px, 22px);
    line-height: 140%;
  }
}

h3, .title-h3 {
  font-size: ac(25px, 22px);
  line-height: normal;
  font-weight: 600;
}

h4, .title-h4 {
  font-size: ac(20px, 18px);
  line-height: 140%;
  font-weight: 400;

  @mixin max-sm {
    line-height: 155%;
  }
}

h5, .title-h5 {
  font-size: ac(28px, 20px);
  font-weight: 500;
  line-height: 1.5;
}

h6, .title-h6 {
  font-size: ac(24px, 18px);
  font-weight: 500;

  line-height: 2;
}

p {
  font-size: ac(18px, 16px);
  font-weight: 400;
  color: var(--cl-medium-grey);

  line-height: 144%;

  @mixin max-sm {
    line-height: 162%;
  }
}

li {
  font-size: ac(18px, 16px);
  font-weight: 400;
  color: var(--cl-medium-grey);

  line-height: 144%;

  @mixin max-sm {
    line-height: 162%;
  }
}

a {
  font-size: ac(20px, 16px);
  font-weight: 500;
  display: inline-block;
  line-height: 100%;
}

.content-element {
  font-size: ac(18px, 16px);
  font-weight: 400;
  color: var(--cl-medium-grey);

  line-height: 144%;

  @mixin max-sm {
    line-height: 162%;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6, .title-h1, .title-h2, .title-h3, .title-h4, .title-h5, .title-h6 {
  }

  blockquote {
    padding-left: 16px;
    border-left: 4px solid var(--cl-light-grey);

    &:not(:last-child) {
      margin-bottom: ac(6px, 8px);
    }
  }

  h2, h3, .title-h2, .title-h3 {
    &:not(:last-child) {
      margin-bottom: ac(24px, 20px);
    }
  }

  h4, .title-h4 {
    color: var(--cl-medium-grey);
    font-weight: 500;

    &:not(:last-child) {
      margin-bottom: ac(20px, 16px);
    }
  }

  ol {
    list-style: decimal;

    li {
      list-style-position: inside;
    }
  }

  ul {
    list-style: none;

    li {
      position: relative;
      padding-left: ac(27px, 20px);

      &:before {
        position: absolute;
        left: ac(12px, 9px);
        top: 11px;
        content: '';
        width: 3px;
        height: 3px;
        background: var(--cl-grey);
      }
    }
  }

  ul,
  ol {
    &:not(:last-child) {
      margin-bottom: ac(6px, 8px);
    }
  }

  p {
    &:not(:last-child) {
      margin-bottom: ac(6px, 8px);
    }
  }

  p,
  li {
    a {
      font: inherit;
      font-weight: 500;
      text-decoration: underline;
      text-decoration-color: transparent;
      transition: all 0.3s ease;
      color: var(--cl-red);

      &:hover {
        text-decoration-color: var(--cl-red);
      }
    }
  }

  &.first-paragraph-big {
    p {
      &:first-child {
        color: var(--cl-medium-grey);
        font-size: ac(20px, 18px);
        line-height: 140%;
        font-weight: 500;

        &:not(:last-child) {
          margin-bottom: ac(20px, 16px);
        }

        @mixin max-sm {
          line-height: 155%;
        }
      }
    }
  }

  &.article-section__text {
    max-width: 100%;

    h2,
    h3,
    h4,
    p,
    li, .title-h2, .title-h3, .title-h4 {
      color: var(--cl-grey);
    }

    p,
    ul,
    ol {
      + h2, .title-h2 {
        padding-top: 24px;
      }

      + h3, .title-h3 {
        padding-top: 20px;
      }

      + h4, .title-h4 {
        padding-top: 18px;
      }

      a {
        word-wrap: break-word;
        overflow-wrap: break-word;
        max-width: 100%;
      }
    }

    h4, .title-h4 {
      font-size: ac(18px, 16px);
      font-weight: 700;
      line-height: normal;

      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }

    /*ul,
    ol {
      &:not(:last-child) {
        margin-bottom: ac(20px, 16px);
      }
    }*/
  }

  .text-red {
    color: var(--cl-red) !important;
  }
}

.dark-bg {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  li, .title-h1, .title-h2, .title-h3, .title-h4, .title-h5, .title-h6 {
    color: var(--cl-white);
  }

  .content-element.first-paragraph-big {
    p {
      color: var(--cl-white);
    }
  }
}
