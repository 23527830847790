.our-history-section {
  padding-top: ac(100px, 40px);
  padding-bottom: ac(70px, 40px);
  overflow: hidden;

  &__wrap {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
  }

  &__top {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    padding-left: 432px;
    margin-bottom: ac(-61px, -30px);
    position: relative;
    z-index: 10;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      right: -100%;
      width: calc(100% - 422px);
      background: linear-gradient(
        200deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 1) 50%,
        rgba(255, 255, 255, 0) 90%,
        rgba(255, 255, 255, 0) 100%
      );
    }

    .slider-buttons {
      .slider-btn {
        @mixin sm {
          margin-top: 0;
        }
      }
    }
  }

  &__title {
    max-width: calc(100% - 110px);
    @mixin max-line-length-one;
  }

  &__slider {
    width: 100%;
    max-width: 304px;
    margin-left: 0;
    overflow: visible;
    min-height: ac(300px, 300px);

    .swiper-wrapper {
      align-items: stretch;
    }
  }

  &__item-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: auto;
    min-height: ac(300px, 300px);

    &:not(.swiper-slide-active) {
      .our-history-section__item {
        width: 304px !important;
        transform: translateY(30px);
      }
    }

    &.swiper-slide-prev {
      .our-history-section__item-text {
        &:not(.our-history-section__item-text--second) {
          transition: opacity 0.2s ease 0.1s, transform 0.1s ease;
        }

        &.our-history-section__item-text--second {
          transition: opacity 0.1s ease 0s, transform 0.1s ease;
        }
      }
    }

    &.swiper-slide-active {
      width: 412px !important;
      .our-history-section__item {
        margin-top: 0;
        width: 412px !important;
        min-height: ac(300px, 280px);
      }

      .our-history-section__item-text {
        &:not(.our-history-section__item-text--second) {
          transition: opacity 0.2s ease, transform 0.5s ease;
          position: absolute;
          left: 0;
          top: 0;
          opacity: 0;
        }

        &.our-history-section__item-text--second {
          position: relative;
          opacity: 1;
        }
      }
    }

    &.swiper-slide-next {
      .our-history-section__item {
        transition: min-height 0.2s ease, transform 0.2s ease;
      }
    }
  }

  &__item {
    width: 100%;
    background: var(--cl-white);
    box-shadow: 0px 4px 20px 5px rgba(0, 0, 0, 0.05);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: ac(30px, 20px);
    height: auto;
    min-height: 151px;
    transition: min-height 0.2s ease, transform 0.2s ease;
    overflow: hidden;
    transform: translateY(0);
    user-select: none;
  }

  &__item-title {
    font-size: ac(25px, 20px);
    color: var(--cl-red);
    line-height: normal;
    margin-bottom: 20px;
  }

  &__item-content {
    position: relative;
    width: 100%;
    max-height: 272px;

    .simplebar-track.simplebar-vertical {
      width: 3px;
      background: var(--cl-medium-grey);
      border-radius: 2px;
      .simplebar-scrollbar {
        background: var(--cl-red);
        border-radius: 2px;
        &:before {
          content: none;
        }
      }
    }
  }

  &__item-text {
    transition: opacity 0.3s ease 0.1s, transform 0.1s ease;
    transform-origin: top left;

    &:not(.our-history-section__item-text--second) {
      p,
      li {
        font-size: 16px;
        font-weight: 600;
        transition: all 0.3s ease;
        line-height: normal;
        color: var(--cl-grey);
      }

      @mixin min-media 441 {
        @mixin max-line-length 2;
      }
    }

    &--second {
      position: absolute;
      top: 0;
      left: 0;
      font-size: 25px;
      width: 352px;
      opacity: 0;
      transition: opacity 0.1s ease 0s, transform 0.1s ease;
      padding-right: 6px;

      p,
      li {
        font-size: 25px;
        font-weight: 600;
        transition: all 0.3s ease;
        line-height: normal;
        color: var(--cl-grey);
      }
    }
  }

  @mixin max-lg {
    &__top {
      padding-left: 0;
      margin-bottom: 24px;
    }

    &__item-container {
      &:not(.swiper-slide-active) {
        .our-history-section__item {
          transform: translateY(0);
        }
      }
    }

    &__item {
      transform: translateY(0) !important;
    }
  }

  @mixin media 440 {
    &__slider {
      max-width: 95%;
      min-height: 200px;
    }

    &__item-container {
      min-height: 200px;

      &:not(.swiper-slide-active) {
        .our-history-section__item {
          width: 100% !important;
        }
      }

      &.swiper-slide-active {
        width: 100% !important;
        .our-history-section__item {
          width: 100% !important;
          min-height: 200px;
        }
      }
    }

    &__item {
      height: auto;
    }

    &__item-text {
      &.our-history-section__item-text--second {
        display: none;
      }

      &:not(.our-history-section__item-text--second) {
        position: relative !important;
        width: 100% !important;
        left: auto !important;
        top: auto !important;
        opacity: 1 !important;

        p,
        li {
          font-size: 20px;
        }
      }
    }
  }
}
