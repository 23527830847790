.global-opportunities {
  padding-top: ac(100px, 30px);
  padding-bottom: ac(100px, 38px);
  overflow: hidden;

  &__wrap {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: row-reverse;
  }

  &__content {
    width: 45.34%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    @mixin max-xl {
      width: 47%;
    }
  }

  &__title {
    &:not(:last-child) {
      margin-bottom: ac(26px, 12px);
    }
  }

  &__text {
    &:not(:last-child) {
      margin-bottom: ac(28px, 28px);
    }
  }

  &__slider-container {
    width: 49.22%;

    .slider-buttons {
      justify-content: flex-end;
      position: relative;
      z-index: 2;

      .slider-btn {
        margin-top: 0;
        margin-bottom: 20px;
      }
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 10px;

    .job-card {
      width: 100%;
    }
  }

  &__slider {
    width: 100%;
    max-height: 524px;
    overflow: visible;
    clip-path: inset(-20px -20px -200px -20px);

    &:not(.swiper-initialized) {
      .swiper-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 10px;
      }
    }

    .swiper-slide {
      transition: opacity 0.3s ease, box-shadow 0.3s ease,
        background-color 0.3s ease;

      &.swiper-slide-prev {
        opacity: 0;
        pointer-events: none;
      }

      &.swiper-slide-next {
        + .swiper-slide {
          + .swiper-slide {
            opacity: 0;
          }
        }
      }
    }
  }
}

@mixin max-md {
  .global-opportunities {
    &__wrap {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    }

    &__content {
      width: 100%;
    }

    &__text {
      h4, .title-h4 {
        max-width: 95%;
      }
    }

    &__slider-container {
      margin-top: 28px;
      width: 100%;
      max-width: 100%;
    }
  }
}

@mixin max-xs {
  .global-opportunities {
    &__text {
      h4, .title-h4 {
        max-width: 100%;
      }
    }
  }
}
