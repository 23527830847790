.teams-section {
  padding-top: ac(100px, 40px);
  padding-bottom: ac(100px, 40px);
  overflow: hidden;

  &__wrap {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
  }

  &__grid {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-column-gap: ac(20px, 14px);
    grid-row-gap: ac(60px, 24px);
  }

  @mixin media 840 {
    &__grid {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }

  @mixin max-sm {
    &__grid {
      grid-template-columns: repeat(1, minmax(0, 1fr));
      max-width: 390px;
    }
  }

  @mixin media 425 {
    &__grid {
      max-width: 100%;
    }
  }
}
