.page-not-found {
  position: relative;

  &__wrap {
    padding-top: 120px;
    padding-bottom: 120px;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    h1,
    p {
      text-align: center;
    }
  }

  &:before {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    bottom: 0;
    width: 58.68%;
    background: var(--cl-light-grey);
  }

  &__content {
    width: auto;
    background: var(--cl-medium-grey);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: ac(40px, 20px);
    padding: ac(50px, 36px) ac(40px, 26px) ac(50px, 48px);
    max-width: ac(628px, 455px);
    position: relative;
    z-index: 2;
  }
}
