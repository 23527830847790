.article-section {
  padding-bottom: ac(100px, 60px);
  overflow: hidden;

  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  &__content {
    width: 100%;
    max-width: ac(844px, 690px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    @mixin max-md {
      max-width: 100%;
    }

    .line-decor {
      margin-top: ac(50px, 32px);
    }

    .grid-policy-list {
      width: 100%;
      max-width: 620px;
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      grid-column-gap: ac(20px, 14px);
      grid-row-gap: 0;

      li, p {
        margin-bottom: 0 !important;
      }
    }
  }

  &__author-info {
    padding-top: 20px;

    display: grid;
    grid-template-columns: 100px 1fr;
    grid-column-gap: ac(16px, 10px);
    grid-row-gap: 8px;
  }

  &__author-info-item {
    font-size: 16px;
    strong {
      font-weight: 600;
      color: var(--cl-grey);
    }
  }

  &__buttons {
    margin-top: ac(30px, 24px);
  }

  &.job-page {
    .article-section__text {
      ul,
      ol {
        &:not(:last-child) {
          margin-bottom: ac(30px, 10px);
        }
      }

      p {
        &:not(:last-child) {
          margin-bottom: ac(5px, 6px);
        }
      }

      ul,
      ol,
      p {
        + h4, .title-h4 {
          padding-top: ac(22px, 18px);
        }
      }
    }
  }
}
