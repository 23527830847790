.first-internal-section {
  padding-top: ac(190px, 130px);
  padding-bottom: ac(100px, 40px);
  overflow: hidden;
  margin-bottom: ac(92px, 0px);
  z-index: 10;

  &:before {
    position: absolute;
    content: '';
    right: 0;
    top: 0;
    height: ac(300px, 325px);
    width: 58.68%;
    background: var(--cl-light-grey);
  }

  &__wrap {
    padding-top: ac(68px, 32px);
    position: relative;
    z-index: 2;
    min-height: ac(509px, 298px);
  }

  &__content {
    width: 100%;
    background: var(--cl-medium-grey);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: ac(50px, 36px) ac(40px, 26px) ac(46px, 36px);
    max-width: ac(628px, 455px);
    position: relative;
    z-index: 2;
  }

  &__title {
    &:not(:last-child) {
      margin-bottom: ac(18px, 14px);
    }
  }

  &__subtitle {
    color: var(--cl-light-grey) !important;

    &:not(:last-child) {
      margin-bottom: ac(26px, 18px);
    }
  }

  &__image {
    @mixin aspect-ratio 1142, 509;
    width: 79.31vw;
    position: absolute;
    right: ac(-82px, -160px);
    top: 0;

    @mixin min-media 1441 {
      right: calc((-1) * (100vw - 1276px) / 2);
      max-width: 1280px;
    }

    @mixin min-media 1700 {
      right: auto;
      left: 180px;
    }
  }

  @mixin max-md {
    &__wrap {
      padding-top: 0;
      padding-bottom: 38vw;
    }
    &__content {
      max-width: 100%;
    }

    &__image {
      width: 100%;
      top: auto;
      bottom: 0;
      right: -6vw;
    }
  }

  @mixin media 425 {
    &__wrap {
      padding-bottom: 53vw;
    }

    &__image {
      @mixin aspect-ratio 1142, 700;
      position: absolute;
    }
  }

  &.about-page {
    padding-bottom: 0;
  }

  &.hiring-page,
  &.teams-page {
    padding-bottom: 0;
    margin-bottom: ac(100px, 40px);
  }

  &.sectors-page {
    margin-bottom: ac(100px, 40px);
  }
}
