.first-article-section {
  margin-top: ac(154px, 82px);
  padding-top: ac(50px, 48px);
  padding-bottom: ac(70px, 44px);
  overflow: hidden;
  min-height: ac(425px, 300px);
  margin-bottom: ac(43px, 40px);
  z-index: 10;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    background: var(--cl-medium-grey);
    z-index: -1;
    bottom: ac(68px, 40px);
  }

  &__wrap {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &__title {
    color: var(--cl-light-grey);
    max-width: 49.22%;
    padding-bottom: ac(93px, 40px);
  }

  &__image {
    @mixin aspect-ratio 629, 450;
    width: 49.29%;
    position: absolute;
    right: 0;
    bottom: ac(-68px, -40px);

    img {
      object-fit: contain;
    }
  }

  &__subtitle {
    font-weight: 500;
    color: var(--cl-red);

    &:not(:last-child) {
      margin-bottom: ac(26px, 24px);
    }
  }

  &__time {
    font-weight: 500;
    color: var(--cl-light-grey);

    &:not(:last-child) {
      margin-bottom: ac(26px, 24px);
    }
  }

  &__list {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    grid-column-gap: ac(40px, 18px);
    grid-row-gap: ac(24px, 16px);

    &:not(:last-child) {
      margin-bottom: ac(30px, 24px);
    }
  }

  &__list-item {
    font-size: ac(25px, 18px);
    font-weight: 600;
    color: var(--cl-grey);

    line-height: normal;
  }

  &.blog-page {
    @mixin max-sm {
      .first-article-section__wrap {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
      }

      .first-article-section__title {
        padding-bottom: 0;
        max-width: 100%;
      }

      .first-article-section__image {
        position: relative;
        left: auto;
        bottom: auto;
        margin-top: 32px;
        width: 90%;
        margin-left: auto;
        margin-bottom: -30px;
      }
    }

    @mixin media 425 {
      .first-article-section__image {
        width: 100%;
      }
    }
  }

  &.job-page {
    padding-top: 38px;
    margin-top: ac(165px, 82px);
    background: var(--cl-light-grey);
    margin-bottom: ac(100px, 50px);
    min-height: ac(367px, 300px);

    &:before {
      content: none;
    }

    .first-article-section__title {
      color: var(--cl-grey);
      max-width: 100%;
      padding-bottom: ac(40px, 24px);
    }

    .first-article-section__time {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      color: var(--cl-medium-grey);
      grid-column-gap: ac(20px, 12px);
      grid-row-gap: 8px;
      font-weight: 600;
      font-size: ac(16px, 14px);
    }
  }
}
