.job-card {
  padding: ac(32px, 26px) ac(30px, 26px) ac(33px, 26px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  transition: box-shadow 0.3s ease, background-color 0.3s ease;
  box-shadow: 0px 4px 20px 5px rgba(0, 0, 0, 0.05);
  background: var(--cl-white);
  min-height: 168px;

  &__company {
    font-size: ac(18px, 16px);
    font-weight: 500;
    color: var(--cl-red);

    line-height: normal;

    &:not(:last-child) {
      margin-bottom: ac(13px, 12px);
    }
  }

  &__title {
    font-size: ac(25px, 22px);
    font-weight: 600;
    color: var(--cl-grey);
    line-height: normal;
    @mixin max-line-length 4;

    &:not(:last-child) {
      margin-bottom: ac(18px, 19px);
    }
  }

  &__list {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    grid-column-gap: ac(25px, 16px);
    grid-row-gap: ac(25px, 14px);
  }

  &__list-item {
    font-size: ac(16px, 14px);
    font-weight: 600;
    color: var(--cl-grey);

    line-height: normal;
  }

  &:hover {
    background-color: var(--cl-light-grey);
    box-shadow: 0px 4px 20px 5px rgba(0, 0, 0, 0.1);
  }
}
